const approvalCreate = {
  state: {
    pageType: 'list',
    approvalType: 'special',
    beginUpdate: new Date(),
    detailId: null,
    companyId: null,
    publisherId: null,
    userId: null,
    summaryType: ''
  },
  mutations: {
    set_approvalCreate_pageType (state, data) {
      state.pageType = data
    },
    set_approvalCreate_approvalType (state, data) {
      state.approvalType = data
    },
    set_approvalCreate_update (state, data) {
      state.beginUpdate = data
    },
    set_approvalCreate_detailId (state, data) {
      state.detailId = data
    },
    set_approvalCreate_companyId (state, data) {
      state.companyId = data
    },
    set_approvalCreate_publisherId (state, data) {
      state.publisherId = data
    },
    set_approvalCreate_userId (state, data) {
      state.userId = data
    }
  },
  getters: {

  },
  actions: {

  }
}

export default approvalCreate
