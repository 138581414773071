<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent" ></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent" ></component>
      </div>
      <div
        slot="bottom_box_content"
        style="padding:10px 10px;"
      >
        <component
          :is="curBottomComponent"
        ></component>
      </div>
      <div slot="bg_map"></div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
import taskStoreModule from '@/store/modules/approvalCreate'
export default {
  mixins: [sysMixins],
  components: {
    Container,
    // 通用审批
    speciallist: () => import('@/components/approvalCreate/SpecialList'),
    specialdetail: () => import('@/components/approvalCreate/SpecialDetail'),

    // 罚单
    finelist: () => import('@/components/approvalCreate/FineList'),
    finedetail: () => import('@/components/approvalCreate/FineDetail'),

    // 通知
    noticelist: () => import('@/components/approvalCreate/NoticeList'),
    noticedetail: () => import('@/components/approvalCreate/NoticeDetail'),

    // 新建&编辑
    Left: () => import('@/components/approvalCreate/CreateApproval')
  },
  methods: {
    initTagEvent () {
    // 注册头部事件
      this.setLinkRouterMaps(['taskList', () => {
        this.$store.commit('set_approvalCreate_pageType', 'list')
      }])
    },
    initComponents () {
      // 清空便签项
      this.setRightComponent(null)
      this.setShowRightBlock(false)
      this.setShowMap(false)
      this.setBottomHeight('90%')
      this.setBottomComponent(this.approvalType + this.pageType)

      this.setLinkTagArray({ key: 'taskList', value: this.approvalType === 'notice' ? '通知清单' : '任务列表' })

      switch (this.pageType) {
        case 'list':

          this.$store.commit('set_approvalCreate_detailId', null)
          this.setLeftComponent('Left')
          this.setShowLeftBlock(true)
          this.setActivedTagName('taskList')
          break
        case 'detail':
          this.setLeftComponent(null)
          this.setShowLeftBlock(false)
          this.setLinkTagArray({ key: 'taskDetail', value: this.approvalType === 'notice' ? '通知详情' : '任务详情' })
          this.setActivedTagName('taskDetail')
          break
        default:
          break
      }
    }
  },
  watch: {
    pageType () {
      this.initComponents()
      this.initTagEvent()
    },
    approvalType () {
      this.initComponents()
      this.initTagEvent()
    }
  },
  mounted () {
    if (!this.$store.hasModule('approvalCreate')) {
      this.$store.registerModule('approvalCreate', taskStoreModule)
      this.$store.commit('set_approvalCreate_companyId', this.$store.getters.token.userInfo.companyId)
      this.$store.commit('set_approvalCreate_publisherId', this.$store.getters.token.userInfo.publisherId)
      this.$store.commit('set_approvalCreate_userId', this.$store.getters.token.userInfo.userId)
    }
    this.initComponents()
  },
  computed: {
    pageType () {
      return this.$store.state.approvalCreate ? this.$store.state.approvalCreate.pageType : 'list'
    },
    approvalType () {
      return this.$store.state.approvalCreate ? this.$store.state.approvalCreate.approvalType : 'special'
    }
  },
  destroyed () {
    // 卸载reconciliation动态store模块
    this.$store.unregisterModule('approvalCreate')
    this.setShowLeftBlock(false)
    this.setShowMap(false)
    this.setShowRightBlock(false)
    this.setBottomHeight('90%')
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  }
}
</script>
